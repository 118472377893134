/* Mixins */
.parking-index-component {
  min-width: 1236px !important;
}
.parking-index-component .page-body-margin {
  margin: 16px;
}
.parking-index-component .parking-card-item:last-child {
  margin-right: 0;
}
.parking-index-component .parking-gauge-chart .parking-gauge-chart-content {
  height: 400px;
  background-color: #fff;
}
.parking-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-title {
  padding-right: 8px;
  border-right: solid 1px #EDEEF0;
  font-size: 14px;
  font-weight: bold;
}
.parking-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-count {
  font-size: 14px;
  font-weight: bold;
}
.parking-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-desc {
  font-size: 12px;
}
.parking-index-component .parking-gauge-chart .parking-gauge-chart-content .parking-gauge-chart-text-wrapper {
  width: 160px;
  margin: 24px auto 0 auto;
}
.parking-index-component .in-out-switch {
  position: absolute;
  top: 50px;
}